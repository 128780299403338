import img1 from '../../images/service/1.webp'
import img2 from '../../images/service/2.webp'
import img3 from '../../images/service/3.webp'
import img4 from '../../images/service/4.webp'
import img5 from '../../images/service/6.webp'
import img7 from '../../images/service/7.webp'


export const data = [
	{
		id: 1,
		h2: 'Кладка Печей (чистка) и Каминов',
		name: 'строительство под ключ',
		text: `Строим печи и камины по оригинальным и уникальным проектам. Проектируем печные сооружения по индивидуальному заказу с учетом всех пожеланий клиентов.`,
		img: img2,
		alt: '',
		link: 'печи-и-камины',
	},
	{
		id: 2,
		h2: 'Строительство и внутренняя отделка Бань и Саун',
		name: 'строительство под ключ',
		text: `Осуществляем проектирование, подбор материалов, транспортировку всех необходимых элементов, строительство бани,
		строительство печи.`,
		img: img1,
		alt: '',
		link: 'бани-и-сауны'
	},
	
	{
		id: 3,
		h2: 'Стоительство и ремонт кровли под ключ',
		name: 'ремонт крыши в Могилеве и Могилевском районе',
		text: `Производим ремонт кровли из всех видов покрытий. Опытные монтажники проконсультируют по вопросам закупки необходимых качественных материалов и крепежных элементов по низким ценам. Сделаем качественный ремонт кровли в установленные сроки.`,
		img: img3,
		alt: '',
		link: 'ремонт-крыши'
	},
	{
		id: 4,
		h2: 'Услуга сантехника в Могилеве',
		name: 'с выездом к клиенту',
		text: `У вас протекла труба, образовался засор, течет унитаз, капает из крана или необходимо провести сантехнику с нуля? Выполняем любые сантехнические работы недорого, качественно и в срок.`,
		img: img4,
		alt: '',
		link: 'сантехник'
	},
	{
		id: 5,
		h2: 'Строительство забора из профнастила',
		name: 'устновка забора под ключ',
		// text: `Оказываем услуги по отделке квартир, домов и офисов в Могилеве и окрестностях. Квалифицированные мастера по укладке плитки производят облицовочные работы любой сложности. Керамическая, декоративная, клинкерная плитка, мозаика, плитка под камень, кирпич, гипс — на стены и пол. Внешняя и внутренняя укладка плитки в Могилёве — качественно и по приятным ценам.`,
		text: `Оказываем услуги по строительству заборов из профильной трубы и профнастила с(без) доставкой материалов со скидкой под ключ в Могилёве и Могилевской районе — качественно и по доступеным ценам.`,
		img: img5,
		alt: '',
		link: 'забор'
	},
	{
		id: 6,
		h2: 'Обшивка дома сайдингом',
		name: 'отделка сайдингом',
		text: `
		Мы предлагаем качественную услугу по обшивке дома сайдингом. Это надежное и долговечное решение для защиты вашего дома от внешних воздействий и придания ему эстетического вида. Обращайтесь к нам, и мы сделаем ваш дом надежным и красивым!
		`,
		img: img7,
		alt: 'обшивка дома сайдингом',
		link: 'обшивка сайдингом'
	},
]