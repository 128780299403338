import { Image } from 'antd';
import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from '../../images/slider/1.webp'
import img2 from '../../images/slider/2.webp'
import img3 from '../../images/slider/3.webp'
import img4 from '../../images/slider/4.webp'
import img5 from '../../images/slider/5.webp'
import img6 from '../../images/slider/6.webp'
import img7 from '../../images/slider/7.webp'
import img8 from '../../images/slider/8.webp'

import imgb1 from '../../images/slider/b1.webp'
import imgb2 from '../../images/slider/b2.webp'
import imgb3 from '../../images/slider/b3.webp'
import imgb4 from '../../images/slider/b4.webp'
import imgb5 from '../../images/slider/b5.webp'
import imgb6 from '../../images/slider/b6.webp'
import imgb7 from '../../images/slider/b7.webp'
import imgb8 from '../../images/slider/b8.webp'
import imgb9 from '../../images/slider/b9.webp'
import imgb10 from '../../images/slider/b10.webp'
import imgb11 from '../../images/slider/b11.webp'

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1
	}
};

const SliderComp = ({ bani, zabor, pechi }) => {
	return (
		<section className='py-10 mx-7 '>
			<div className='container mx-auto'>
				<div className='flex flex-col justify-between mb-8'>
					<div className='text-center'>
						<h4 className='text-3xl uppercase'>
							Почему выбирают нас
						</h4>
					</div>
				</div>

				{
					bani ?
						<Carousel responsive={responsive}>
							<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
								<Image src={img1} preview={false} className='rounded-t-md' />
								<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
									<p className='font-semibold text-white px-4 pt-4'>
										Баня под ключ
									</p>
								</div>
							</div>

							<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
								<Image src={img2} preview={false} className='rounded-t-md' />
								<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
									<p className='font-semibold text-white px-4 pt-4'>
										Баня под ключ
									</p>
								</div>
							</div>

							<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
								<Image src={img3} preview={false} className='rounded-t-md' />
								<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
									<p className='font-semibold text-white px-4 pt-4'>
										Баня под ключ
									</p>
								</div>
							</div>
						</Carousel>

						:
						null
				}
				{
					zabor ?
					<Carousel responsive={responsive}>
						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={img4} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
									Забор под ключ
								</p>
							</div>
						</div>

						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={img5} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
									Забор под ключ
								</p>
							</div>
						</div>

						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={img6} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
									Забор под ключ
								</p>
							</div>
						</div>
						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={img7} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
									Забор под ключ
								</p>
							</div>
						</div>
						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={img8} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
									Забор под ключ
								</p>
							</div>
						</div>
					</Carousel>
					:
					null
				}
				{
					pechi ?
					<Carousel responsive={responsive}>
						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={imgb1} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
									Печь под ключ
								</p>
							</div>
						</div>

						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={imgb2} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
								Печь под ключ
								</p>
							</div>
						</div>

						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={imgb3} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
								Печь под ключ
								</p>
							</div>
						</div>
						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={imgb4} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
								Печь под ключ
								</p>
							</div>
						</div>
						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={imgb5} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
								Печь под ключ
								</p>
							</div>
						</div>
						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={imgb6} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
								Печь под ключ
								</p>
							</div>
						</div>
						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={imgb7} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
								Печь под ключ
								</p>
							</div>
						</div>
						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={imgb8} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
								Печь под ключ
								</p>
							</div>
						</div>
						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={imgb9} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
								Печь под ключ
								</p>
							</div>
						</div>
						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={imgb10} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
								Печь под ключ
								</p>
							</div>
						</div>
						<div className='bg-white rounded-md overflow-hidden mx-2 relative'>
							<Image src={imgb11} preview={false} className='rounded-t-md' />
							<div className='absolute bottom-1 left-0 bg-black/30 overflow-hidden'>
								<p className='font-semibold text-white px-4 pt-4'>
								Печь под ключ
								</p>
							</div>
						</div>
					</Carousel>
					:
					null
				}
			</div>
		</section>
	)
}

export default SliderComp