import React from 'react'
import { motion } from "framer-motion"
import { titleAnimation2, titleAnimation } from '../../constants/animation/AnimationConst'

export const SectionGreyMain = () => {
	return (
		<section className='bg-[#042A3F] text-[#F4F4F4] px-5 py-10 mt-20'>
			<motion.div
				className=''
				initial="hidden"
				whileInView="visible"
			>
				<motion.h4
					className='text-2xl text-[#AFD93B]'
					variants={titleAnimation}
				>
					remontmogilev.by
				</motion.h4>
				<motion.p
					className='font-light leading-6 text-sm tracking-wide'
					variants={titleAnimation2}
				>
					Мы - строительная бригада, специализирующаяся на строительстве заборов, крыш, бань, печей и каминов. Наша команда состоит из опытных специалистов, которые имеют необходимые знания и навыки для выполнения работ любой сложности.
					<span className='block mt-2'>
						Мы понимаем, что каждый клиент имеет свои индивидуальные потребности и требования, поэтому мы всегда готовы предложить наилучшие решения для каждого проекта. Мы можем помочь вам выбрать наиболее подходящие материалы и дизайн, которые соответствуют вашим потребностям и бюджету.
					</span>
					<span className='block mt-2'>
						Качественное выполнение работ - наша главная цель. Мы используем только качественные материалы от проверенных производителей, чтобы гарантировать долговечность и надежность конструкций. Кроме того, мы следим за технологическими новинками и используем современное оборудование, чтобы обеспечить максимально качественное выполнение работ.
					</span>
					<span className='block mt-2'>
						Мы также понимаем, что время - это деньги, поэтому мы всегда стараемся выполнить работы в срок и с минимальными затратами. Наша команда работает быстро и эффективно, не ущербом качеству, чтобы вы могли наслаждаться результатом своей работы как можно скорее.
					</span>
					<span className='block mt-2'>
						Безопасность наших клиентов и сотрудников - наша главная приоритет. Мы следим за соблюдением всех норм и правил безопасности на рабочем месте, используем только безопасные материалы и инструменты, чтобы исключить возможность несчастных случаев.

					</span>
					<span className='block mt-2'>
						Наша бригада также предлагает услуги по ремонту и реставрации заборов, крыш, бань, печей и каминов. Мы можем помочь вам вернуть вашу конструкцию к первоначальному виду и улучшить ее характеристики.
					</span>
					<span className='block mt-2'>
						Свяжитесь с нами сегодня, чтобы узнать больше о наших услугах и получить бесплатную консультацию от наших специалистов. Мы готовы помочь вам воплотить в жизнь любые ваши идеи и проекты.
					</span>
				</motion.p>
			</motion.div>
		</section>
	)
}
