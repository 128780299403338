import banya from '../../images/service/1-1.webp'
import pechka from '../../images/service/2-2.webp'
import kamin from '../../images/service/2-3.webp'
import krysha from '../../images/service/3-2.webp'
import plumber from '../../images/service/4-2.webp'
import zabor from '../../images/service/6-2.webp'
import sajding2 from '../../images/service/7-3.webp'
import sajding3 from '../../images/service/7-2.webp'
import sajding4 from '../../images/service/7-5.webp'
import sajding5 from '../../images/service/7-6.webp'



export const dataBani = [
	{
		id: 1,
		h1: 'Строительство и отделка бани и сауны в Могилеве',
		h2: 'У нас вы можете заказать профессиональное строительство и отделку бани и сауны. Мы предлагаем широкий спектр услуг по строительству и отделке бани и сауны. Наши опытные мастера гарантируют высокое качество работ и индивидуальный подход к каждому клиенту. Звоните нам прямо сейчас!',
		img: banya,
		alt: 'строительство и отделка бани',
		h3: 'Стоительство бани:',
		list: [
			'Фундамент бани.',
			'Деревянная или блочная конструкция.',
			'Монтаж бани.',
			'Кровля бани.',
			'Строительство печи (противопожарный разрыв).',
			'Отделка бани сайдингом или вагонкой.'
		],
		content: [
			{
				h4: 'Построить баню под ключ недорого в Могилеве',
				p: `Строительство бани под ключ - это сложный и ответственный процесс, который требует профессионального подхода и опыта. Баня - это не только место для отдыха и релаксации, но и важный элемент инфраструктуры дома.

				При строительстве бани под ключ необходимо учитывать множество факторов, таких как размер бани, ее конструктивные особенности, выбор материалов, оборудование и т.д. Важно учитывать все требования безопасности и нормы пожарной безопасности.
				
				Оптимальный вариант при строительстве бани под ключ - это обращение к профессионалам, которые имеют необходимую квалификацию и опыт в данной области. Они помогут выбрать подходящий тип бани, подготовят проект, проведут оценку и предоставят вам подробную информацию о стоимости работ.
				
				При обращении к профессионалам по строительству бани под ключ, важно убедиться в их квалификации и опыте работы. Они должны иметь все необходимые сертификаты и лицензии, а также положительные отзывы от предыдущих клиентов.
				
				Кроме того, при строительстве бани под ключ, необходимо учитывать ее дальнейшее обслуживание и техническое обслуживание. Это поможет сохранить ее работоспособность и продлить срок службы.
				
				В итоге, строительство бани под ключ - это важный и ответственный процесс, который требует профессионального подхода и опыта. Обращение к профессионалам поможет вам получить качественную установку и настроить баню на оптимальный режим работы, что обеспечит комфорт и релаксацию в вашем доме.
			
				`
			},
			{
				h4: 'Стоимость строительства бани в Могилеве',
				p: `Строительство бани - это сложный и ответственный процесс, который требует профессионального подхода и опыта. Стоимость строительства бани может варьироваться в зависимости от многих факторов, таких как размер бани, ее конструктивные особенности, выбор материалов, оборудование и т.д.

				Для того чтобы узнать точную стоимость строительства бани, необходимо обратиться к профессионалам, которые проведут оценку и предоставят вам подробную информацию о стоимости работ.
				
				Кроме того, при выборе компании для строительства бани, необходимо учитывать не только стоимость работ, но и квалификацию и опыт специалистов, а также их репутацию на рынке.
				
				Важно помнить, что качественное строительство бани - это важный и ответственный процесс, который требует профессионального подхода и опыта. Неквалифицированное строительство может привести к непредвиденным последствиям, таким как повреждение конструкций и т.д.
				
				Кроме стоимости строительства бани, необходимо учитывать и дополнительные расходы, такие как стоимость материалов, обслуживание и техническое обслуживание в дальнейшем.
				
				В итоге, стоимость строительства бани может варьироваться в зависимости от многих факторов. Для того чтобы получить точную информацию о стоимости работ, необходимо обратиться к профессионалам, которые проведут оценку и предоставят вам подробную информацию о стоимости работ и дополнительных расходах. Кроме того, необходимо учитывать квалификацию и опыт специалистов, а также их репутацию на рынке.`
			},
			{
				h4: 'Процесс строительства бани под ключ',
				p: `Процесс строительства бани - это сложный и ответственный процесс, который требует профессионального подхода и опыта. Важно учитывать множество факторов, таких как размер бани, ее конструктивные особенности, выбор материалов, оборудование и т.д.

				Первый этап процесса строительства бани - это подготовка проекта. Необходимо определить размер и конструктивные особенности бани, выбрать материалы, оборудование и т.д. Важно учитывать все требования безопасности и нормы пожарной безопасности.
				
				Второй этап - это подготовка места для строительства бани. Необходимо провести земляные работы, подготовить фундамент и т.д.
				
				Третий этап - это возведение стен и крыши. Важно учитывать все особенности конструкции бани, чтобы обеспечить ее прочность и надежность.
				
				Четвертый этап - это установка дверей, окон и оборудования. Необходимо выбрать подходящее оборудование, установить его на оптимальный режим работы и настроить на нужную температуру.
				
				Пятый этап - это отделка бани. Важно выбрать подходящие материалы для отделки, чтобы обеспечить комфорт и уют внутри бани.
				
				Шестой этап - это техническое обслуживание и обслуживание в дальнейшем. Важно проводить регулярное техническое обслуживание, чтобы обеспечить работоспособность бани и продлить ее срок службы.
				
				В итоге, процесс строительства бани - это сложный и ответственный процесс, который требует профессионального подхода и опыта. Обращение к профессионалам поможет вам получить качественную установку и настроить баню на оптимальный режим работы, что обеспечит комфорт и релаксацию в вашем доме.`
			},

		]
	},
]


export const dataKaminy = [
	{
		id: 2,
		h1: 'Печник Могилев: кладка печи и камина под ключ',
		h2: 'Оказываем услугу по кладке (строительству), ремонту и чистки печей, каминов и дымовых труб. Наши специалисты обеспечат качественное выполнение работ в Могилеве и близлежащих районах. Звоните нам для заказа услуг.',
		img: pechka,
		alt: 'кладка печей и каминов',
		h3: 'Услуга печника по кладке печи:',
		list: [
			'Кладка печи.',
			'Установка печей длительного горения и металлических каминных топок.',
			'Оформление металлических банных котлов.',
			'Реконструкция или ремонт не работающих (дымящих) печей и каминов различных видов, дымоходов.',
			'Возведение фундамента под печь, а также дымовых труб и шахт.',
			'Демонтаж, разборка старых и “неудачных” печных сооружений.',
			'Перенос печей каминов на другое место.',
			'Чистка печей и дымовых труб (дымоходов).',
		],
		h3_2: 'Услуга печника по кладке камина:',
		img2: kamin,
		list2: [
			'Кладка камина.',
			'Установка топки.',
			'Подключение топки к дымоходу.',
			'Монтаж дымоход.',
			'Монтаж облицовки, который определяет внешний вид вашего камина.',
		],
		content: [
			{
				h4: 'Печник Могилев',
				p: `Печник Могилев – это специалист, который занимается изготовлением и монтажом печей, каминов и других отопительных систем. Если вы ищете квалифицированного печника в Могилеве, то вы попали по адресу!
				Наши специалисты обладают богатым опытом в изготовлении и монтаже печей и каминов. Мы предлагаем нашим клиентам не только качественные услуги, но и гарантию на выполненные работы.
				Изготовление печей – это долгий и сложный процесс, который требует высокой квалификации. Наши мастера не только знают технологию изготовления печей, но и обладают опытом работы с различными материалами, такими как кирпич, камень и металл. Мы гарантируем высокое качество работ и долговечность наших изделий.
				Монтаж печей и каминов – это также ответственный и сложный процесс, который необходимо доверять только квалифицированным специалистам. Наши мастера обладают всеми необходимыми знаниями и навыками для правильного монтажа печей и каминов, а также соблюдают все необходимые нормы и требования безопасности.
				Мы предлагаем широкий выбор печей и каминов различных форм и размеров. Наш печник в Могилеве поможет вам выбрать наиболее подходящий вариант для вашего дома, учитывая все ваши пожелания и особенности помещения.
				Если вы ищете квалифицированного печника в Могилеве, обращайтесь к нам! Мы гарантируем высокое качество услуг, а также доступные цены. Наши специалисты готовы ответить на все ваши вопросы и помочь сделать правильный выбор.
				Кроме того, мы предлагаем свои услуги не только по изготовлению и монтажу печей, но и по их ремонту и обслуживанию. Наши специалисты проведут диагностику вашей печи, определят неисправности и устранят их в кратчайшие сроки.
				В нашей компании мы стремимся предоставлять нашим клиентам только лучшие услуги и гарантировать полное удовлетворение от сотрудничества снами. Мы понимаем, что каждый клиент уникален и имеет свои индивидуальные потребности. Поэтому мы всегда идем на встречу и стараемся найти наилучшие решения для каждого конкретного случая.
				Кроме того, мы ценим своих клиентов и всегда стремимся установить долгосрочные отношения с каждым из них. Мы не ищем быстрых побед и краткосрочной прибыли, а строим наши отношения на основе доверия и взаимной выгоды.
				Мы понимаем, что наша репутация и успех нашего бизнеса зависят от качества наших услуг и удовлетворенности наших клиентов. Поэтому мы постоянно работаем над совершенствованием наших процессов и повышением квалификации наших сотрудников.
				Если вы ищете надежного и профессионального печника в Могилеве, обращайтесь к нам. Мы гарантируем высокое качество наших услуг, индивидуальный подход к каждому клиенту и конкурентноспособные цены.`
			},
			{
				h4: 'Установка каминов и печей',
				p: `Установка каминов и печей - это сложный и ответственный процесс, который требует профессионального подхода и опыта. Камин или печь не только являются функциональными элементами отопления, но и важным декоративным элементом интерьера, который создает уютную и теплую атмосферу в доме.

				При установке каминов и печей необходимо учитывать множество факторов, таких как размер помещения, его конструктивные особенности, тип и мощность камина или печи, а также безопасность и эффективность работы.
				
				Оптимальный вариант при установке каминов и печей - это обращение к профессионалам, которые имеют необходимую квалификацию и опыт в данной области. Они помогут выбрать подходящий тип камина или печи, установят его в соответствии с требованиями и нормами безопасности, а также настроят на оптимальный режим работы.
				
				При обращении к профессионалам по установке каминов и печей, важно убедиться в их квалификации и опыте работы. Они должны иметь все необходимые сертификаты и лицензии, а также положительные отзывы от предыдущих клиентов.
				
				Кроме того, при установке каминов и печей, необходимо учитывать их дальнейшее обслуживание и техническое обслуживание. Это поможет сохранить их работоспособность и продлить срок службы.
				
				В итоге, установка каминов и печей - это важный и ответственный процесс, который требует профессионального подхода и опыта. Обращение к профессионалам поможет вам получить качественную установку и настроить камин или печь на оптимальный режим работы, что обеспечит теплую и уютную атмосферу в вашем доме.`
			},
			{
				h4: 'Стоимость установки и монтажа печей и каминов',
				p: `Стоимость установки и монтажа печей и каминов может варьироваться в зависимости от многих факторов, таких как тип и мощность камина или печи, сложность установки, размер помещения, его конструктивные особенности и т.д.

				Для того чтобы узнать точную стоимость установки и монтажа печей и каминов, необходимо обратиться к профессионалам, которые проведут оценку и предоставят вам подробную информацию о стоимости работ.
				
				Кроме того, при выборе компании для установки и монтажа печей и каминов, необходимо учитывать не только стоимость работ, но и квалификацию и опыт специалистов, а также их репутацию на рынке.
				
				Важно помнить, что качественная установка и монтаж печей и каминов - это важный и ответственный процесс, который требует профессионального подхода и опыта. Неквалифицированная установка или монтаж может привести к непредвиденным последствиям, таким как пожар, повреждение конструкций и т.д.
				
				Кроме стоимости установки и монтажа, необходимо учитывать и дополнительные расходы, такие как стоимость материалов, обслуживание и техническое обслуживание в дальнейшем.
				
				В итоге, стоимость установки и монтажа печей и каминов может варьироваться в зависимости от многих факторов. Для того чтобы получить точную информацию о стоимости работ, необходимо обратиться к профессионалам, которые проведут оценку и предоставят вам подробную информацию о стоимости работ и дополнительных расходах. Кроме того, необходимо учитывать квалификацию и опыт специалистов, а также их репутацию на рынке.`
			},
			{
				h4: 'Как происходит процесс монтажа камина или печи?',
				p: `Процесс монтажа камина или печи - это сложный и ответственный процесс, который требует профессионального подхода и опыта. Некорректно установленный камин или печь может привести к непредвиденным последствиям, таким как пожар, повреждение конструкций и т.д.

				Перед началом монтажа камина или печи необходимо провести оценку помещения и выбрать подходящий тип и мощность камина или печи. Также необходимо учитывать конструктивные особенности помещения и требования безопасности.
				
				Далее следует подготовка помещения для установки камина или печи. Это включает в себя установку дымохода, который должен соответствовать требованиям безопасности и нормам пожарной безопасности. Также необходимо предусмотреть место для хранения дров и других материалов для работы камина или печи.
				
				После подготовки помещения и установки дымохода следует установка камина или печи. Это требует определенных навыков и специализированного оборудования. При установке камина или печи необходимо учитывать требования безопасности и нормы пожарной безопасности.
				
				После установки камина или печи следует настройка на оптимальный режим работы. Это включает в себя настройку температуры и регулировку дымохода. Настройка камина или печи должна проводиться только профессиональными специалистами.
				
				Важно помнить, что качественный монтаж камина или печи - это важный и ответственный процесс, который требует профессионального подхода и опыта. Неквалифицированный монтаж может привести к непредвиденным последствиям, таким как пожар, повреждение конструкций и т.д.
				
				В итоге, процесс монтажа камина или печи - это сложный и ответственный процесс, который требует профессионального подхода и опыта. При выборе компании для монтажа камина или печи необходимо учитывать квалификацию и опыт специалистов, а также их репутацию на рынке.`
			},
			{
				h4: 'Услуга по кладке печи в Могилеве',
				p: `
				Кладка печи - это сложный процесс, который требует профессиональных знаний и опыта. Качественно выполненная кладка печи обеспечивает не только комфортное отопление помещения, но и экономию на затратах на топливо. Если вы планируете установить печь в своем доме, то услуга по кладке печи - это то, что вам нужно.

Процесс кладки печи начинается с выбора подходящего места для установки. Это может быть кухня, гостиная, баня или другое помещение. Далее необходимо выбрать материал для кладки печи. Самым распространенным материалом является кирпич, но также можно использовать камень, шлакоблоки или другие материалы.

После выбора места и материала начинается процесс кладки печи. Это сложный процесс, который требует точности и внимательности. Каждый ряд должен быть аккуратно выложен, чтобы печь была прочной и надежной. Также необходимо учесть все технические нюансы, такие как подвод топлива, установка дверки и т.д.

После завершения кладки печи необходимо провести ее тестирование. Это позволит убедиться в том, что печь работает корректно и не имеет никаких дефектов. Также важно провести обучение по использованию печи, чтобы избежать возможных неприятностей.

Услуга по кладке печи - это не только процесс установки печи, но и гарантия качественной работы и долговечности конструкции. Профессиональный мастер, который занимается кладкой печи, имеет опыт работы с различными типами печей и может выполнить работу быстро и качественно.

В итоге, услуга по кладке печи - это ответственный и сложный процесс, который требует профессиональных знаний и опыта. Выбирая услугу по кладке печи, необходимо учитывать все факторы, такие как опыт мастера, качество материалов и т.д. Качественно выполненная кладка печи обеспечит комфортное отопление и экономию на затратах на топливо в течение многих лет.
				`
			},
			{
				h4: 'Строительство печи',
				p: `
				Строительство печи - это сложный и ответственный процесс, который требует профессиональных знаний и опыта. Качественно выполненная печь обеспечивает не только комфортное отопление помещения, но и экономию на затратах на топливо. Если вы планируете построить печь в своем доме, то вам необходимо знать некоторые особенности строительства печи.

Первым шагом в строительстве печи является выбор места для ее установки. Это может быть кухня, гостиная, баня или другое помещение. Важно учитывать, что печь должна быть установлена в соответствии со всеми нормами и правилами пожарной безопасности.

Далее необходимо выбрать материал для строительства печи. Самым распространенным материалом является кирпич, но также можно использовать камень, шлакоблоки или другие материалы. Важно учитывать, что выбранный материал должен быть прочным и долговечным.

После выбора места и материала начинается процесс строительства печи. Это сложный процесс, который требует точности и внимательности. Каждый ряд должен быть аккуратно выложен, чтобы печь была прочной и надежной. Также необходимо учесть все технические нюансы, такие как подвод топлива, установка дверки и т.д.

После завершения строительства печи необходимо провести ее тестирование. Это позволит убедиться в том, что печь работает корректно и не имеет никаких дефектов. Также важно провести обучение по использованию печи, чтобы избежать возможных неприятностей.

Строительство печи - это не только процесс установки печи, но и гарантия качественной работы и долговечности конструкции. Профессиональный мастер, который занимается строительством печи, имеет опыт работы с различными типами печей и может выполнить работу быстро и качественно.

В итоге, строительство печи - это ответственный и сложный процесс, который требует профессиональных знаний и опыта. Выбирая услугу по строительству печи, необходимо учитывать все факторы, такие как опыт мастера, качество материалов и т.д. Качественно выполненная печь обеспечит комфортное отопление и экономию на затратах на топливо в течение многих лет.
				`
			},
		]
	},
]

export const dataKrysha = [
	{
		id: 3,
		h1: 'Строительство и ремонт крыши в Могилёве',
		h2: 'Оказываем полный комплекс услуг по ремонту и строительству кровли из любых материалов на любых объектах под ключ в Могилеве и Могилевской области.',
		img: krysha,
		alt: 'Строительство и ремонт крыши',
		h3: 'Оказываем следующее услуги по ремонту крыши:',
		list: [
			'Демонтаж старой кровли.',
			'Ремонт конькового прогона.',
			'Ремонт стропильных ног.',
			'Ремонт опорной рамы.',
			'Ремонт кровельного покрытия.',
			'Местный ремонт крыши',
			'Капитальный ремонт крыши',
			'Замена кровельного покрытия.',
			'Обнаружение протечки.',
			'Обнаружение трещин.',
		],
		content: [
			{
				h4: 'Ремонт кровли',
				p: `Ремонт кровли - это важный процесс, который требует профессионального подхода и опыта. Ремонт кровли под ключ - это комплексный подход к ремонту, который включает в себя все этапы работы, начиная от диагностики и заканчивая полной заменой кровельного покрытия.

				Первый этап ремонта кровли - это диагностика. Необходимо определить причину повреждения кровли, исследовать состояние кровельного покрытия и определить объем работ.
				
				Второй этап - это подготовка кровли к ремонту. Необходимо провести необходимые работы по очистке кровли от мусора, грязи и других загрязнений.
				
				Третий этап - это ремонт кровли. В зависимости от причины повреждения, могут быть проведены различные виды работ, такие как замена кровельных материалов, установка новых элементов кровли, герметизация швов и т.д.
				
				Четвертый этап - это отделка кровли. Важно выбрать подходящие материалы для отделки кровли, чтобы обеспечить ее прочность и надежность.
				
				Пятый этап - это техническое обслуживание и обслуживание в дальнейшем. Важно проводить регулярное техническое обслуживание, чтобы обеспечить работоспособность кровли и продлить ее срок службы.
				
				Ремонт кровли под ключ - это комплексный подход к ремонту, который позволяет обеспечить качественный ремонт кровли и продлить ее срок службы. Обращение к профессионалам поможет вам получить качественный ремонт кровли и настроить ее на оптимальный режим работы, что обеспечит комфорт и безопасность в вашем доме. Важно учитывать квалификацию и опыт специалистов, а также их репутацию на рынке при выборе компании для ремонта кровли под ключ.`
			},
			{
				h4: 'Как определить необходимость ремонта крыши?',
				p: `Кровля - это одна из самых важных частей любого здания, ведь именно она защищает нас от воздействия погодных условий и обеспечивает комфортное проживание. Но со временем кровля может изнашиваться и требовать ремонта. Поэтому необходимость ремонта кровли под ключ - это важный процесс, который необходимо проводить регулярно.

				Одной из основных причин, почему необходим ремонт кровли, является ее износ. При этом кровельные материалы могут терять свои свойства и становиться менее прочными, что приводит к утечке воды и другим проблемам. Кроме того, кровля может повреждаться из-за погодных условий, таких как сильный ветер, дождь, снег и т.д.
				
				Еще одной причиной необходимости ремонта кровли под ключ является несоответствие кровли современным стандартам и требованиям. Так, например, если кровля была установлена много лет назад, то она может не соответствовать современным требованиям к теплоизоляции и вентиляции.
				
				Наконец, необходимость ремонта кровли под ключ может возникнуть в случае, если вы планируете продавать свой дом. В этом случае кровля должна быть в отличном состоянии, чтобы повысить стоимость недвижимости и привлечь большее количество потенциальных покупателей.
				
				В итоге, необходимость ремонта кровли под ключ - это важный процесс, который требует профессионального подхода и опыта. Регулярный ремонт кровли поможет сохранить ее в отличном состоянии и продлить срок ее службы. Поэтому, если вы заметили какие-либо проблемы с вашей кровлей, необходимо обратиться к профессионалам, которые проведут диагностику и предложат наилучшие варианты ремонта.`
			},
			{
				h4: 'Стоимость кровельных работ',
				p: `Стоимость ремонта кровли под ключ может варьироваться в зависимости от многих факторов, таких как тип кровли, ее состояние, объем работ и т.д. Ремонт кровли - это важный процесс, который требует профессионального подхода и опыта. 

				Для того чтобы узнать точную стоимость ремонта кровли, необходимо обратиться к профессионалам, которые проведут оценку и предоставят вам подробную информацию о стоимости работ.
				
				Кроме того, при выборе компании для ремонта кровли под ключ, необходимо учитывать не только стоимость работ, но и квалификацию и опыт специалистов, а также их репутацию на рынке.
				
				Важно помнить, что качественный ремонт кровли - это важный и ответственный процесс, который требует профессионального подхода и опыта. Неквалифицированный ремонт может привести к непредвиденным последствиям, таким как повреждение кровли и т.д.
				
				Кроме стоимости ремонта кровли, необходимо учитывать и дополнительные расходы, такие как стоимость материалов, обслуживание и техническое обслуживание в дальнейшем.
				
				В итоге, стоимость ремонта кровли под ключ может варьироваться в зависимости от многих факторов. Для того чтобы получить точную информацию о стоимости работ, необходимо обратиться к профессионалам, которые проведут оценку и предоставят вам подробную информацию о стоимости работ и дополнительных расходах. Кроме того, необходимо учитывать квалификацию и опыт специалистов, а также их репутацию на рынке при выборе компании для ремонта кровли под ключ.
				
				
				`
			},
		]
	},
]

export const dataPlumber = [
	{
		id: 4,
		h1: 'Услуги сантехника в Могилеве: быстрый выезд к клиенту',
		h2: 'Выполняем любые сантехнические работы на любом объекте в Могилеве и Могилевской районе. Опыт 10 лет. Сантехник по низкой цене!',
		img: plumber,
		alt: 'услуги сантехника',
		h3: 'Мы делаем:',
		list: [
			'Монтаж сантехнического оборудования',
			'Замена труб водоснабжения с металлических на пластиковые (полипропилен)',
			'Отопление',
			'Водоснабжение',
			'Канализация',
			'Устранение засоров',
			'Устранение протечек',
			'Установка смесителей и душевых',
			'Установка унитазов',
			'Установка стиральных машин',
			'Установка посудомоечных машин',
		],
		content: [
			{
				h4: 'Вызов сантехника в Могилеве',
				p: `Вызов сантехника - это важный процесс, который требует профессионального подхода и опыта. Сантехник - это специалист, который занимается установкой, ремонтом и обслуживанием систем водоснабжения, канализации и других элементов сантехники в доме.

				Одной из основных причин, почему может потребоваться вызов сантехника, является утечка воды. Утечка воды может возникнуть из-за повреждения труб, неправильной установки сантехнических устройств, а также других причин. В этом случае необходимо немедленно обратиться к специалисту, который проведет диагностику и предложит наилучшие варианты ремонта.
				
				Кроме того, вызов сантехника может потребоваться и в случае, если необходимо произвести установку новых сантехнических устройств. В этом случае сантехник поможет выбрать подходящие материалы и провести установку согласно всем необходимым требованиям и нормам.
				
				Наконец, вызов сантехника может потребоваться и для проведения технического обслуживания и обслуживания в дальнейшем. Регулярное техническое обслуживание поможет обеспечить работоспособность сантехнических устройств, а также продлить срок их службы.
				
				В итоге, вызов сантехника - это важный процесс, который требует профессионального подхода и опыта. Обращение к профессионалам поможет вам получить качественный ремонт сантехники и настроить ее на оптимальный режим работы, что обеспечит комфорт и безопасность в вашем доме. Важно учитывать квалификацию и опыт специалистов, а также их репутацию на рынке при выборе компании для вызова сантехника.`
			},
			{
				h4: 'Услуги сантехника в Могилеве?',
				p: `Услуги сантехника - это неотъемлемая часть обслуживания жилых и коммерческих помещений. Сантехник - это специалист, который занимается установкой, ремонтом и обслуживанием систем водоснабжения, канализации и других элементов сантехники в доме.

				Одной из основных услуг, которые предоставляет сантехник, является устранение утечек воды. Утечка воды может возникнуть из-за повреждения труб, неправильной установки сантехнических устройств, а также других причин. В этом случае необходимо немедленно обратиться к специалисту, который проведет диагностику и предложит наилучшие варианты ремонта.
				
				Кроме того, сантехник может предоставить услуги по установке новых сантехнических устройств. В этом случае сантехник поможет выбрать подходящие материалы и провести установку согласно всем необходимым требованиям и нормам.
				
				Наконец, сантехник может предоставить услуги по техническому обслуживанию и обслуживанию в дальнейшем. Регулярное техническое обслуживание поможет обеспечить работоспособность сантехнических устройств, а также продлить срок их службы.
				
				В итоге, услуги сантехника - это важная часть обслуживания жилых и коммерческих помещений. Обращение к профессионалам поможет вам получить качественный ремонт сантехники и настроить ее на оптимальный режим работы, что обеспечит комфорт и безопасность в вашем доме. Важно учитывать квалификацию и опыт специалистов, а также их репутацию на рынке при выборе компании для предоставления услуг сантехника.`
			},
			{
				h4: 'Стоимость услуги сантехника в Могилеве',
				p: `Стоимость услуги сантехника может варьироваться в зависимости от многих факторов. Одним из главных факторов является сложность работы. Например, устранение простой утечки воды может быть дешевле, чем замена всей системы водоснабжения.

				Также на стоимость услуги сантехника может влиять выбранный материал для установки или ремонта сантехники. Например, использование более дорогих материалов может увеличить стоимость работы.
				
				Кроме того, стоимость услуги сантехника может зависеть от региона, в котором вы находитесь. В некоторых регионах стоимость услуг может быть выше из-за высокой конкуренции или более высоких затрат на транспортировку и материалы.
				
				Важно учитывать, что стоимость услуги сантехника может включать не только стоимость работы, но и стоимость материалов, необходимых для проведения работ. Поэтому важно уточнить все детали и расходы перед началом работы.
				
				Наконец, стоимость услуги сантехника может зависеть от квалификации и опыта специалиста. Более опытные и квалифицированные специалисты могут брать большую плату за свои услуги.
				
				В итоге, стоимость услуги сантехника может варьироваться в зависимости от многих факторов. Чтобы получить наилучшую цену на услуги сантехника, важно выбрать профессиональную компанию с хорошей репутацией на рынке и уточнить все детали и расходы перед началом работы.`
			},
		],
	}
]

export const dataZabor = [
	{
		id: 5,
		h1: 'Строим заборы из металлопрофиля в Могилеве',
		h2: 'Строительство заборов качественно, быстро и недорого. Выполняем работы как под ключ, так и частями. Закажите установку забора из металлопрофиля по низкой цене!',
		img: zabor,
		alt: 'строительство забора',
		h3: 'Выполняем следующие работы по строительству забора:',
		list: [
			'Фундамент',
			'Столбики',
			'Ворота',
			'Калидки',
			'Услуги сварщика',
			'Монтаж забора',
			'Покраска забора',
		],
		content: [
			{
				h4: 'Установка забора в Могилеве',
				p: `Установка забора - это важный процесс, который требует профессионального подхода и опыта. Забор - это не только элемент ограждения, но и важный элемент безопасности и конфиденциальности в вашем доме.

				Первым шагом при установке забора является выбор материала. Материалы для заборов могут быть различными, в зависимости от целей, которые вы хотите достичь. Например, деревянный забор может придать вашему участку теплый и естественный вид, но он может потребовать регулярного обслуживания. Металлический забор, с другой стороны, может быть более долговечным и требовать меньше обслуживания.
				
				После выбора материала следует провести замеры и планирование установки забора. Важно учитывать все особенности участка, такие как рельеф местности, наличие подземных коммуникаций и другие факторы, которые могут повлиять на процесс установки.
				
				Следующим шагом является непосредственно установка забора. Это может быть достаточно сложным процессом, требующим опыта и профессионального подхода. При установке забора важно учитывать все технические требования и нормы, чтобы обеспечить его прочность и долговечность.
				
				В итоге, установка забора - это важный процесс, который требует профессионального подхода и опыта. Обращение к профессионалам поможет вам получить качественный забор, который будет служить вам долгие годы и обеспечит необходимую безопасность и конфиденциальность на вашем участке. Важно учитывать квалификацию и опыт специалистов, а также их репутацию на рынке при выборе компании для установки забора.`

			},

			{
				h4: `
				Забор под ключ
				`,
				p: `Забор под ключ - это услуга, которая позволяет получить готовое решение для ограждения вашего участка. Это означает, что компания, предоставляющая услугу, берет на себя все этапы установки забора, начиная от выбора материала и заканчивая финальной установкой.

				Это позволяет сэкономить ваше время и усилия, так как вам не придется заниматься поиском материалов, замерами и установкой забора. Вместо этого вы можете доверить все этапы профессионалам и получить готовый забор, который будет служить вам долгие годы.
				
				Одним из главных преимуществ забора под ключ является возможность получить качественный результат. Компания, предоставляющая услугу, имеет опыт и профессиональное оборудование для установки забора, что позволяет обеспечить качество и долговечность забора.
				
				Кроме того, забор под ключ может быть более экономичным решением, так как компания, предоставляющая услугу, может получить скидки на материалы и оборудование благодаря своим постоянным партнерским отношениям.
				
				Важно учитывать, что стоимость забора под ключ может варьироваться в зависимости от выбранного материала, сложности работ и других факторов. Однако, в целом, забор под ключ может быть более экономичным и удобным решением для тех, кто хочет получить качественный забор без лишних забот и усилий.
				
				В итоге, забор под ключ - это удобная и экономичная услуга, которая позволяет получить готовое решение для ограждения вашего участка. Обращение к профессионалам поможет вам получить качественный забор, который будет служить вам долгие годы.`

			},
			{
				h4: `
				Стоимость работы по строительству забора из профнастила`,
				p: `Стоимость работы по строительству забора из профнастила может варьироваться в зависимости от многих факторов. Одним из главных факторов является размер забора. Чем больше забор, тем выше будет стоимость работы.

				Кроме того, на стоимость работы может влиять выбранный материал для забора. Профнастил - это один из самых популярных материалов для строительства заборов благодаря своей прочности и долговечности. Однако, стоимость профнастила может варьироваться в зависимости от его качества и производителя.
				
				Стоимость работы по строительству забора из профнастила также может зависеть от сложности установки. Например, если забор должен быть установлен на неровной поверхности или в условиях ограниченного доступа, то это может увеличить стоимость работы.
				
				Важно учитывать, что стоимость работы по строительству забора из профнастила может включать не только стоимость материалов, но и стоимость работы специалистов. Поэтому важно уточнить все детали и расходы перед началом работы.
				
				Наконец, стоимость работы по строительству забора из профнастила может зависеть от квалификации и опыта специалистов. Более опытные и квалифицированные специалисты могут брать большую плату за свои услуги.
				
				В итоге, стоимость работы по строительству забора из профнастила может варьироваться в зависимости от многих факторов. Чтобы получить наилучшую цену на услуги по строительству забора из профнастила, важно выбрать профессиональную компанию с хорошей репутацией на рынке и уточнить все детали и расходы перед началом работы.`
			},
			{
				h4: `
				Установка забора из металлопрофиля
				`,
				p: `Установка забора из профнастила - это популярный и надежный способ оградить ваш участок от посторонних глаз и обеспечить безопасность. Профнастил - это легкий, прочный и долговечный материал, который легко устанавливается и не требует особого ухода.

				Первым шагом при установке забора из профнастила является выбор материала и дизайна забора. Профнастил может быть доступен в различных цветах и размерах, что позволяет выбрать наиболее подходящий вариант для вашего участка.
				
				После выбора материала и дизайна следует провести замеры и планирование установки забора. Важно учитывать все особенности участка, такие как рельеф местности, наличие подземных коммуникаций и другие факторы, которые могут повлиять на процесс установки.
				
				Следующим шагом является непосредственно установка забора из профнастила. Это может быть достаточно простым процессом, который может быть выполнен самостоятельно, но важно учитывать все технические требования и нормы, чтобы обеспечить его прочность и долговечность.
				
				Одним из главных преимуществ забора из профнастила является его долговечность и устойчивость к различным погодным условиям. Кроме того, забор из профнастила не требует особого ухода и может служить вам долгие годы.
				
				Важно учитывать, что установка забора из профнастила может быть достаточно простым процессом, который может быть выполнен самостоятельно. Однако, если у вас нет необходимого опыта и инструментов, то лучше обратиться к профессионалам, чтобы обеспечить качество и долговечность забора.
				
				В итоге, установка забора из профнастила - это популярный и надежный способ оградить ваш участок от посторонних глаз и обеспечить безопасность. Обращение к профессионалам поможет вам получить качественный забор, который будет служить вам долгие годы.`
			}
		]
	},
]



export const dataSajding = [
	{
		id: 6,
		h1: 'Обшивка дома сайдингом в Могилёве и Могилевской области',
		h2: 'Хотите надежную защиту своего дома и придать ему эстетический вид? Мы предлагаем услугу по обшивке дома сайдингом под ключ. Наши специалисты профессионально выполнят все работы: от замера до монтажа. Обращайтесь к нам, и мы сделаем ваш дом красивым и надежным!',
		img: sajding2,
		alt: 'обшивка дома сайдингом',
		h3: 'Выполняем следующие виды работ по обшивке сайдингом:',
		list: [
			'1. Монтажные работы по установке сайдинга на стены.',
			'2. Монтажные работы по оформлению откосов.',
			'3. Монтажные работы по прокладке гидроизоляции.',
			'4. Устройство обрешетки из дерева по деревянной основе.',
			'5. Устройство обрешетки из дерева на кирпич или бетон.',
			'6. Устройство обрешетки из металла по деревянной основе.',
			'7. Устройство обрешетки из металла по кирпичу и бетону.',
			'8. Устройство утепления.',
			'9. Обработка дерева противопожарным и био-септиком.',
			'10. Обустройство отливов.',
			'11. Установка водостоков.',
		],
		h4: 'Сайдинг - это материал, который используется для отделки наружных стен дома. Существует множество различных видов сайдинга, каждый из которых имеет свои преимущества и недостатки. Ниже приведен список наиболее популярных видов сайдинга с которыми мы работаем:',
		h4_list: [
			{
				list: '1. Пластиковый сайдинг - это материал, который выполнен из полимерных материалов. Пластиковый сайдинг отличается легкостью в установке, низкой стоимостью и устойчивостью к воздействию погоды.',
				id: 1,
				alt: 'Пластиковый сайдинг',
				img: sajding5
			},
			{
				list: '2. Металлический сайдинг - это прочный и долговечный материал, который может быть выполнен из алюминия, стали или меди. Металлический сайдинг отличается высокой устойчивостью к воздействию погоды и огнестойкостью.',
				id: 2,
				img: sajding3,
				alt: 'Металлический сайдинг',
			},
			{
				list: '3. Деревянный сайдинг - это традиционный материал, который придает дому уютный и естественный вид. Деревянный сайдинг изготавливается из древесных отходов, которые прессуются под воздействием полимерных добавок. Однако, деревянный сайдинг требует регулярного ухода и обработки, чтобы сохранить свою привлекательность.',
				id: 3,
				img: sajding4,
				alt: 'Деревянный сайдинг',
			},
		],

		content: [
			{
				h4: 'Как происходит процесс обшивки дома сайдингом',
				p: `Обшивка дома сайдингом - это одно из самых популярных решений для защиты дома от внешних воздействий и придания ему эстетического вида. Но как происходит процесс обшивки дома сайдингом и как мы делаем это?

				Шаг 1: Замер
				
				Первым этапом является замер. Наши специалисты приезжают на объект и проводят детальный замер фасада дома. В процессе замера учитываются все особенности дома, а также пожелания клиента.
				
				Шаг 2: Подготовка поверхности
				
				После замера начинается подготовка поверхности. Наши специалисты проводят очистку поверхности от старых обоев, краски, мусора и других загрязнений. Далее, если необходимо, производится шпаклевание и выравнивание поверхности.
				
				Шаг 3: Монтаж сайдинга
				
				После подготовки поверхности начинается монтаж сайдинга. Наши специалисты профессионально укладывают сайдинг на поверхность дома. При этом используются только качественные материалы и современное оборудование.
				
				Шаг 4: Дополнительные работы
				
				После монтажа сайдинга, наши специалисты проводят дополнительные работы. Это может быть установка оконных откосов, облицовка балконов и другие работы по желанию клиента.
				
				Шаг 5: Завершение работ
				
				После того, как все работы выполнены, наши специалисты проводят финальную проверку качества. Мы убеждаемся в том, что все работы выполнены в соответствии с высокими стандартами качества.
				
				
				Мы делаем обшивку дома сайдингом профессионально и качественно. Наши специалисты имеют многолетний опыт работы и гарантируют высокое качество услуг. Мы используем только качественные материалы и современное оборудование, что позволяет нам добиваться отличных результатов. Обращайтесь к нам, и мы сделаем ваш дом красивым, надежным и уютным!`

			},

			{
				h4: `
				От чего зависит стоимость обшивки дома сайдингом в Могилеве и Могилевской области
				`,
				p: `
				Обшивка дома сайдингом - это одно из самых популярных решений для защиты дома от внешних воздействий и придания ему эстетического вида. Однако, стоимость обшивки дома сайдингом может варьироваться в зависимости от нескольких факторов.

1. Площадь дома

Одним из основных факторов, влияющих на стоимость обшивки дома сайдингом, является площадь дома. Чем больше площадь, тем выше будет стоимость работ.

2. Тип сайдинга

Тип сайдинга также может влиять на стоимость обшивки дома. Существует несколько видов сайдинга: виниловый, металлический, деревянный и другие. Каждый тип сайдинга имеет свою цену.

3. Сложность монтажа

Сложность монтажа сайдинга также может влиять на стоимость работ. Если дом имеет нестандартную форму или наличие балконов и других элементов, то монтаж сайдинга может быть более сложным и дороже.

4. Дополнительные работы

Дополнительные работы, такие как установка оконных откосов, облицовка балконов и другие, могут также повлиять на стоимость обшивки дома сайдингом.


В целом, стоимость обшивки дома сайдингом зависит от многих факторов. Однако, не стоит экономить на качестве материалов и квалификации специалистов, так как это может привести к дополнительным расходам в будущем. Лучше обратиться к профессионалам, которые смогут выполнить работы качественно и по разумной цене. Наши специалисты имеют многолетний опыт работы и гарантируют высокое качество услуг. Мы используем только качественные материалы и современное оборудование, что позволяет нам добиваться отличных результатов. Обращайтесь к нам, и мы поможем вам выбрать оптимальный вариант обшивки дома сайдингом, который подойдет именно вам!
				`

			},
			{
				h4: `
				Сколько времени занимает обшить дом сайдингом
				`,
				p: `
				Обшивка дома сайдингом - это надежное решение для защиты дома от внешних воздействий и придания ему эстетического вида. Однако, многие люди задаются вопросом, сколько времени занимает обшить дом сайдингом.

Время, затрачиваемое на обшивку дома сайдингом, зависит от нескольких факторов:

1. Площадь дома

Одним из основных факторов, влияющих на время обшивки дома сайдингом, является площадь дома. Чем больше площадь, тем больше времени потребуется на выполнение работ.

2. Сложность монтажа

Сложность монтажа сайдинга также может влиять на время обшивки дома. Если дом имеет нестандартную форму или наличие балконов и других элементов, то монтаж сайдинга может быть более сложным и затянуться во времени.

3. Количество рабочих

Количество рабочих, занятых на монтаже сайдинга, также может влиять на время выполнения работ. Чем больше рабочих, тем быстрее будет выполнена обшивка дома сайдингом.

4. Погодные условия

Погодные условия также могут влиять на время выполнения работ. Если погода плохая, то монтаж сайдинга может затянуться во времени.

В целом, время, затрачиваемое на обшивку дома сайдингом, может варьироваться от нескольких дней до нескольких недель. Однако, наши специалисты профессионально выполняют все работы и стараются сделать это максимально быстро и качественно.



Обращайтесь к нам, и мы поможем вам определить время, необходимое для обшивки дома сайдингом. Наши специалисты имеют многолетний опыт работы и гарантируют высокое качество услуг. Мы используем только качественные материалы и современное оборудование, что позволяет нам добиваться отличных результатов. Обращайтесь к нам, и мы сделаем ваш дом красивым, надежным и уютным в кратчайшие сроки!
				`
			},
			{
				h4: `
				Как правильно выбрать мастера для отделки дома сайдингом
				`,
				p: `
				Отделка дома сайдингом - это один из самых популярных способов улучшить внешний вид жилого помещения. Однако, чтобы получить качественный результат, необходимо правильно выбрать мастера, который выполнит работы.

Первое, что необходимо сделать при выборе мастера для отделки дома сайдингом, это провести небольшой анализ рынка услуг. Изучите отзывы о компаниях, которые занимаются подобными работами, узнайте их репутацию, опыт работы и наличие лицензии.

Далее, обратите внимание на опыт мастера. Чем больше он имеет опыта в отделке домов сайдингом, тем выше вероятность того, что работа будет выполнена профессионально и качественно. Также не забывайте уточнять, какие материалы и инструменты использует мастер.

Одним из ключевых моментов при выборе мастера является его портфолио. Просмотрите фотографии выполненных работ, чтобы убедиться в том, что мастер имеет опыт работы с различными типами сайдинга и может реализовать любые дизайнерские решения.

Также не забывайте узнавать о гарантии на выполненные работы. Хороший мастер всегда предоставляет гарантию на свои услуги, что говорит о его ответственности и профессионализме.

Наконец, не забывайте о цене. Стоимость отделки дома сайдингом может сильно различаться в зависимости от многих факторов, таких как тип сайдинга, сложность работ и т.д. Однако, не стоит выбирать мастера только из-за низкой цены. Цена должна быть адекватной и соответствовать качеству работ.

В итоге, выбор мастера для отделки дома сайдингом - это ответственный шаг, который потребует времени и тщательного анализа рынка услуг. Однако, правильный выбор мастера гарантирует качественный и профессиональный результат, который будет радовать вас многие годы.
				`
			}
		]
	},
]
