import axios from 'axios'
// const token = '5562126487:AAGqX2TBd3toX15OgSCQ2yW55RNfgtBWQko'
const token = '5887190682:AAFZnKisPylcWJA_FK1b8IIUOB9tWKUgP_U'
// const chat_id = '-1001794221917'
const chat_id = '-1001664053966'
const uri_api = `https://api.telegram.org/bot${token}/sendMessage`


export const sendOrderTelegram = async (obj) => {
	const { data } = await axios.post(uri_api, {
		chat_id,
		parse_mode: 'html',
		text: obj,
	})
	return data
}




